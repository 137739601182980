import React from 'react';
import { IconEdit } from '../Icons/IconEdit';

const ButtonEdit = props => (
  <button
    className="btn btn-default btn-sm mr-2"
    title="editieren"
    type="button"
    onClick={props.onClick}
  >
    <IconEdit />
  </button>
);

export { ButtonEdit };
