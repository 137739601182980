import React from 'react';
import { FormattedMessage } from 'react-intl';

const ButtonSave = props => (
  <button className="btn btn-primary" type="submit" onClick={props.onClick}>
    <i className="icon icon-check" /> {props.showLabel && <FormattedMessage id="dashboard.Save" />}
  </button>
);

export { ButtonSave };
