import React from 'react';

import { Item } from './Item';

const PortalTable = props => (
  <>
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Domains</th>
          <th>Safe</th>
          <th>Alter</th>
          <th>Beschreibung</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {props.items && props.items.length > 0
          ? props.items.map(item => (
              <Item
                item={item}
                key={item.id}
                updateItem={props.updateItem}
                deleteItem={props.deleteItem}
              />
            ))
          : null}
      </tbody>
    </table>
  </>
);

export { PortalTable };
