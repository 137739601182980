import React, { useState, useEffect } from 'react';

const SafeSearch = props => {
  const [domain, setDomain] = useState();
  const [cname, setCname] = useState();

  useEffect(() => {
    if (typeof props.onChange === 'function' && domain && cname) {
      props.onChange({ domain: domain, cname: cname }, props.index);
    }
  }, [domain, cname]);

  return (
    <div className="columns" style={{marginBottom: 10}}>
      <div className="column col-6">
        <input
          type="text"
          className="form-input"
          value={props.domain}
          onChange={e => setDomain(e.target.value)}
        ></input>
      </div>
      <div className="column col-6">
        <input
          type="text"
          className="form-input"
          value={props.cname}
          onChange={e => setCname(e.target.value)}
        ></input>
      </div>
    </div>
  );
};

export {SafeSearch};