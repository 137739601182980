import React from 'react';
import { IconDelete } from '../Icons/IconDelete';

const ButtonDelete = props => (
  <button
    className="btn btn-error btn-sm"
    title="löschen"
    type="button"
    onClick={props.onClick}
  >
    <IconDelete />
  </button>
);

export { ButtonDelete };
