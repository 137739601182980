import React, { useState } from 'react';

import { withAppContext } from '../withAppContext';
import { Input } from '../Form/Input';
import { adminApiPortalAdd } from '../../util/adminApi';
import { ButtonAdd } from '../Buttons/ButtonAdd';
import { SafeSearch } from './SafeSearch';

import { injectIntl } from 'react-intl';

const Create = props => {
  /**
   * @type {{id: number, name: string, cname: string, forced: boolean }} initialFormState
   */
  const initialFormState = { id: null, domain: '', name: '', text: '', safeSearches: [] };
  const [item, setItem] = useState(initialFormState);

  /**
   *
   * @param {(import('react').ChangeEvent<HTMLInputElement>} event
   */
  const handleInputChange = event => {
    const { name, value } = event.target;

    let setValue = value;

    setItem({ ...item, [name]: setValue });
  };

  /**
   *
   * @param {import('react').FormEvent} event
   */
  const handleSubmit = async event => {
    event.preventDefault();

    const storeItem = { ...item };

    storeItem.safeSearches = storeItem.safeSearches.filter(value => value !== undefined);

    if (!item.domains || !item.name) return;

    const newItem = await adminApiPortalAdd(props.appContext.token, storeItem);
    props.addItem(newItem);
    setItem(initialFormState);
    props.showCreate(false);
  };

  const { formatMessage } = props.intl;

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <Input label="Name" name="name" value={item.name} handleChange={handleInputChange} />
      <textarea label="Domains" name="domains" value={item.domains} onChange={handleInputChange} />
      <Input label="Text" name="text" value={item.text} handleChange={handleInputChange} />
      <div className={`form-group`}>
        <label className="form-label" htmlFor="age-selector">
          Alter
        </label>
          <select name="age" className="form-select" id="age-selector" onChange={handleInputChange} defaultValue="6">
            <option value="0">{formatMessage({ id: 'children.ages.0' })}</option>
            <option value="6">
              {formatMessage({ id: 'children.ages.6' })}
            </option>
            <option value="12">{formatMessage({ id: 'children.ages.12' })}</option>
            <option value="16">{formatMessage({ id: 'children.ages.16' })}</option>
            <option value="18">{formatMessage({ id: 'children.ages.18' })}</option>
          </select>
      </div>
      <br />
      <h4>SafeSearches</h4>
      <div className="columns">
        <div className="column col-6">
          <h5>Domain</h5>
        </div>
        <div className="column col-6">
          <h5>CNAME</h5>
        </div>
      </div>
      {item.safeSearches.length > 0 &&
        item.safeSearches.map((value, index) => {
          return (
            <SafeSearch
              key={`safesearch-${index}`}
              index={index}
              value={value}
              onChange={(value, index) => {
                item.safeSearches[index] = value;
                setItem({ ...item });
              }}
            />
          );
        })}
      <button
        type="button"
        onClick={() => {
          item.safeSearches.push(undefined);
          setItem({ ...item });
        }}
      >
        addSafesearch
      </button>
      <br />
      <br />
      <div className="form-group">
        <ButtonAdd />
      </div>
    </form>
  );
};

const CreatePortal = withAppContext(injectIntl(Create));

export { CreatePortal };
