import React from 'react';
import { AppContext } from './AppContext';

function withAppContext(Component) {
  return (props) => (
    <AppContext.Consumer>
      {value => <Component {...props} appContext={value} />}
    </AppContext.Consumer>
  );
}

export { withAppContext };
